import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GetTouchForm from "../components/GetTouchForm"
import StickyBox from "react-sticky-box"
import ScrollspyNav from "react-scrollspy-nav"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap"
import {
  CallIcon,
  ProcessIcon,
  ApplyIcon,
  BenifitsIcon,
  HandshakeIcon,
} from "../components/Icons"
import PatternImg from "../images/line-pattern.png"
import RtoBuildImg from "../images/rto-build.svg"
import ZigzagImg from "../images/zig-zag.svg"
import CallUs from "../images/call-us.svg"
import PaymentIcon from "../images/payment.svg"
import PayOffIcon from "../images/payoff.svg"
import OrderIcon from "../images/order-1.svg"
import ApplyRtoIcon from "../images/apply-rto-1.svg"
import CreditCheckImg from "../images/credit-check.svg"
import instantnApproveImg from "../images/in-appro.svg"
import { Section } from "../components/Section"

const RentToOwn = ({location}) => {
  let formId = "Keep Your Metal Building Financial Planning Stable";
  return (
    <Layout location = { location }>
      <SEO title="Rent to own" />
      <section style={{ background: `url(${PatternImg})` }}>
        <Container className="position-relative">
          <Breadcrumb className="beadcrumbs-outer">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Rent To Own</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Section className="section rto-section" pt="100px" pb="100px">
        <Container>
          <Row>
            <Col xl={4}>
              <StickyBox offsetTop={40}>
                <div className="section-heading">
                  <h2 className="section-title">Rent To Own</h2>
                  <span className="section-subtitle"></span>
                </div>
                <div className="section-content d-none d-lg-block">
                  <div className="wheeltab">
                    <ul className="rto-tabs">
                      {typeof window !== "undefined" ? (
                        <ScrollspyNav
                          scrollTargetIds={[
                            "section_1",
                            "section_2",
                            "section_3",
                            "section_4",
                          ]}
                          activeNavClass="is-active"
                        >
                          <li className="about-rto">
                            <a href="#section_1" className="text is-active">
                              <div className="text-inner">
                                <HandshakeIcon /> About RTO
                              </div>
                            </a>
                          </li>
                          <li className="process">
                            <a href="#section_2" className="text">
                              <div className="text-inner">
                                <ProcessIcon />
                                Our Proces
                              </div>
                            </a>
                          </li>

                          <li className="benfits">
                            <a href="#section_3" className="text">
                              <div className="text-inner">
                                <BenifitsIcon />
                                Benefits
                              </div>
                            </a>
                          </li>
                          <li className="get-started">
                            <a href="#section_4" className="text">
                              <div className="text-inner">
                                <ApplyIcon />
                                Get Started
                              </div>
                            </a>
                          </li>
                        </ScrollspyNav>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </StickyBox>
            </Col>
            <Col xl={{ span: 7, offset: 1 }}>
              <div className="low-monthly-program tab-section" id="section_1">
                <img src={RtoBuildImg} className="rto-img" alt="RTO" />
                <h4>Rent to Own Metal Buildings</h4>
                <p>Metal buildings—whether they’re steel barns, one-to-three-car carports, or enormous, durable covers for agricultural equipment, they’re a viable solution to any problem an individual might have when it comes to keeping their valuables safe and protected. Still, sometimes, the outright price of a durable, long-lasting metal building isn’t possible to pay up-front.</p>
                <p>At Carports R Us, we’re more than proud to offer our customers the most affordable options for the highest quality steel buildings—that’s something we consider to be integral to our customer service (something we value immensely). Still, we understand that not every individual, business, or family is in the position to purchase the metal building they’ve been dreaming of outright. Why? Because often, cash on hand for something super necessary like a metal building (which could change your business, your life, and the way you store things!) isn’t always there when you need it. That’s why at Carports R Us, we’re so proud to offer easy financing plans for paying off your metal building at your own rate. We’ve created a flexible rent to own program for our metal buildings to make it easier than ever for you to invest in your metal building set up as soon as possible!</p>
                <div className="cto-section">
                  <span className="title">
                    We invite you to contact our team at Carports R Us as quickly as
                    you can to apply for our rent to own program for the metal
                    building of your dreams!
                  </span>
                  <a href="tel:8773304846" className="btn btn-link">
                    <CallIcon stroke="#CB0000" width="34px" height="34px" />
                    (877) 330-4846
                  </a>
                </div>
              </div>
              <div className="rto-process tab-section" id="section_2">
                <h4>The Rent to Own Process—What You Can Expect</h4>
                <p>The rent to own process is seamless—in other words, you can expect the application for the rent to own payment option to be quick, easy, and affordable. At Carports R Us, we’re proud to ensure a positive customer experience for every person who works with us, and that means guaranteeing a quick, affordable, and direct plan for your payment options!</p>
                <ul className="rto-timeline-list">
                  <img src={ZigzagImg} className="zig-zag-img" alt="..." />
                  <li>
                    <div className="icon-box">
                      <img src={CallUs} alt="Call Us" />
                    </div>
                    <div className="content-box">
                      <h4 className="title title-underline">
                        Talk to Our Representative
                      </h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon-box">
                      <img src={OrderIcon} alt="Order" />
                    </div>
                    <div className="content-box">
                      <h4 className="title title-underline">
                        Place Your Order
                      </h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon-box">
                      <img src={ApplyRtoIcon} alt="Apply" />
                    </div>
                    <div className="content-box">
                      <h4 className="title title-underline">Apply For RTO</h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon-box">
                      <img src={PaymentIcon} alt="Payment" />
                    </div>
                    <div className="content-box">
                      <h4 className="title title-underline">
                        Make a Small Payment
                      </h4>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="why-renting tab-section" id="section_3">
                <div className="section-heading">
                  <h4>Why Renting to Own Isn’t Just a Good Option, But a Great One!</h4>
                  <p>Our rent to own option for your ideal metal building isn’t just a good idea, it’s a great one! Why? Because it takes the stress out of immediate purchasing. If you’re not in a position to outright buy your dream metal building, we never think that should hold you back. That’s why at Carports R Us, we’ve created a streamlined, easy rent to own program that’s guaranteed to make your life simpler!
                  </p>
                  <p>How exactly do we do that with our rent to own program? Here are just a few of the benefits that our rent to own program can offer you!</p>

                </div>
                <div className="section-content">
                  <ul className="why-renting-list">
                    <li>
                      <div className="icon-box">
                        <img src={CreditCheckImg} alt="Credit Check" />
                      </div>
                      <div className="content-box">
                        <h4 className="title title-underline">No Credit Check</h4>
                        <p>We’re proud to offer you a program that’s so simple, you don’t even need a credit check for purchases up to $15,000. Carports R Us rent to own program is all about simplicity and speed that means we’re not going to ding your credit or make you wait around—we just care about getting you the metal building of your dreams!</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon-box">
                        <img src={instantnApproveImg} alt="Pay off" />
                      </div>
                      <div className="content-box">
                        <h4 className="title title-underline">Instant Approval</h4>
                        <p>Hey, we don’t believe in making you wait around, so why would we take forever to approve your payment plan? We guarantee that we can approve your application in a flash—in fact, we can approve it immediately. Apply today to see what we mean!</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon-box">
                        <img src={PayOffIcon} alt="No Early Payoff Penalties" />
                      </div>
                      <div className="content-box">
                        <h4 className="title title-underline">Month-to-Month Contract</h4>
                        <p>We map out an easy, direct, and totally transparent contract for you (no hidden fees or fine print included) that allows you the chance to pay a monthly rate as part of this program. Nothing confusing about this plan!</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon-box">
                        <img src={PayOffIcon} alt="No Early Payoff Penalties" />
                      </div>
                      <div className="content-box">
                        <h4 className="title title-underline">Pay off Anytime</h4>
                        <p>We think this is the best part! You’re not stuck in a monthly contract if you’re able to pay off your balance. You can choose to pay off your metal building anytime within a 60-month period of purchasing—that means you can pay monthly as planned, or you can opt to pay your balance in full without dealing with any early pay penalties whatsoever.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="financing-process tab-section" id="section_4">
                <div className="section-section">
                  <h4 className="title">Keep Your Metal Building Financial Planning Stable</h4>
                  <p>Break away from the status quo & buy your dream metal building today with easy and actionable rent to own programs.</p>
                  <div className="form-section">
                    <GetTouchForm location = { location } formId = { formId } />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default RentToOwn
